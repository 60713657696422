
export default {
  props: {
    title: String,
    imageType: {
      type: String,
      default: 'jpg',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    source: String,
    fullWidth: Boolean,
    url: String,
  },
}
