import Vue from 'vue'

import ContentBox from '@/components/shared/ContentBox'
import ContentBoxGroup from '@/components/shared/ContentBoxGroup'
import ContentTitle from '@/components/shared/ContentTitle'
import ImageSource from '@/components/shared/ImageSource'
import StaticContent from '@/components/shared/StaticContent'
import Wrapper from '@/components/shared/Wrapper'

Vue.component('content-box', ContentBox)
Vue.component('content-box-group', ContentBoxGroup)
Vue.component('content-title', ContentTitle)
Vue.component('image-source', ImageSource)
Vue.component('static-content', StaticContent)
Vue.component('wrapper', Wrapper)
