
export default {
  data() {
    return {
      readAndAgreedTermsOfUse: false,
    }
  },
  methods: {
    goToSavedStep() {
      const step = 1
      const name = this.$t('projectPropertiesParam')
      this.$router.push(
        this.localePath({
          name: 'waterloop-engineering-guide-step-name',
          params: { step, name },
        })
      )
    },
  },
}
