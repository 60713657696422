
export default {
  props: {
    fullWidth: Boolean,
    action: String,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    layout: String,
    isLoading: Boolean,
    icon: [Object, String],
    disabled: Boolean,
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
    floating: Boolean,
    externalLink: String,
    width: String,
    rounded: Boolean,
    backgroundColor: String,
    noHover: Boolean,
  },
  data() {
    return {
      iconFill: 'blue-800',
    }
  },
  computed: {
    getAlignClasses() {
      const classes = {}
      classes.right = this.right
      classes.left = this.left
      classes.top = this.top
      classes.bottom = this.bottom
      return classes
    },
    getClasses() {
      const classes = {}
      if (this.layout) {
        classes[`${this.layout}`] = true
      }
      classes.loading = this.isLoading
      classes.small = this.small
      classes.medium = this.medium
      classes.large = this.large
      classes.icon = this.icon
      classes.floating = this.floating
      classes['icon-only'] = !this.action
      classes['icon-right'] = !!(this.icon && this.icon.right)
      classes['full-width'] = this.fullWidth
      classes.disabled = this.disabled
      classes.rounded = this.rounded
      classes['no-hover'] = this.noHover

      return classes
    },
  },
  mounted() {
    if (this.icon && this.icon.fill) {
      this.iconFill = this.icon.fill
    }
  },
  methods: {
    onHoverButton(bool) {
      if (bool) {
        this.iconFill = 'white'
        return
      }
      if (this.icon && this.icon.fill) {
        this.iconFill = this.icon.fill
      } else if (this.getClasses.tertiary) {
        this.iconFill = 'blue-800'
      } else {
        this.iconFill = 'blue-500'
      }
    },
  },
}
