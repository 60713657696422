const merge = require('deepmerge')

const stateKeys = [
  'calculation-request',
  'calculation-request-region',
  'calculation-request-country',
  'calculation-request-building-demand',
  'calculation-request-plate-heat-exchanger',
  'calculation-request-glycol-percentage',
  'calculation-request-pipe-material',
  'calculation-request-max-pressure-loss',
  'calculation-request-floors',
  'calculation-request-average-height',
  'calculation-request-dhw-diversity',
  'calculation-request-occupancy-diversity',
  'calculation-loading',
  'calculation-not-valid',
  'calculation-response',
  'calculation-response-failed',
]

export const actions = {
  clearStorage() {
    for (let i = 0; i < stateKeys.length; i++) {
      this.$storage.removeUniversal(stateKeys[i])
    }
  },
  clearRegion() {
    this.$storage.removeUniversal('calculation-request-region')
  },
  clearResponse() {
    this.$storage.removeUniversal('calculation-response')
    this.$storage.removeUniversal('calculation-response-failed')
  },
  async setIsNotValid({ dispatch }, value) {
    const storageFloors = await dispatch('getFloors')
    const buildingDemand = await dispatch('getSelectedBuildingDemand')
    let isValid = true
    storageFloors.forEach((floor) => {
      floor.apartments.forEach((apartment) => {
        if (buildingDemand._buildingDemand === 'H/O') {
          if (apartment.code.startsWith('C')) {
            if (apartment.flowRate == null || apartment.heatingLoad == null) {
              isValid = false
            }
          } else if (
            apartment.heatingLoad == null ||
            !apartment.winterEmitter
          ) {
            isValid = false
          }
        }
        if (buildingDemand._buildingDemand !== 'H/O') {
          if (apartment.code.startsWith('C')) {
            if (
              apartment.heatingLoad == null ||
              apartment.coolingLoad == null ||
              apartment.flowRate == null
            ) {
              isValid = false
            }
          } else if (
            apartment.heatingLoad == null ||
            apartment.coolingLoad == null ||
            !apartment.winterEmitter ||
            !apartment.summerEmitter
          ) {
            isValid = false
          }
        }
      })
    })
    this.$storage.setUniversal('calculation-not-valid', !isValid)
  },
  getIsNotValid(_) {
    return this.$storage.getUniversal('calculation-not-valid')
  },
  getHasErrors(_) {
    return this.$storage.getUniversal('calculation-response-failed')
  },
  setHasErrors(_, value) {
    this.$storage.setUniversal('calculation-response-failed', value)
  },
  setIsLoading(_, value) {
    this.$storage.setUniversal('calculation-loading', value)
  },
  getIsLoading(_) {
    return this.$storage.getUniversal('calculation-loading')
  },
  setSelectedRegion(_, value) {
    this.$storage.setUniversal('calculation-request-region', value)
  },
  getSelectedRegion(_, regions) {
    let selectedRegion = this.$storage.getUniversal(
      'calculation-request-region'
    )
    if (!selectedRegion) {
      selectedRegion = regions.find((item) => item._default)
    }
    return selectedRegion
  },
  async getRegions(_, value) {
    const regions = await this.$directusApi.getRegions(value)
    return regions
  },
  async updateFloors({ dispatch }, floors) {
    const data = { update: true, floors }
    this.$storage.setUniversal('calculation-request-floors', floors)
    data.floors = data.floors.map((floor) => {
      return {
        number: floor.number,
        commercialCode: floor.commercialCode,
        apartments: floor.apartments.map((apartment) => {
          return {
            code: apartment.code,
            heatingLoad: apartment.heatingLoad,
            coolingLoad: apartment.coolingLoad,
            flowRate: apartment.flowRate,
            winterEmitter: apartment.winterEmitter
              ? apartment.winterEmitter.name
              : apartment.winterEmitter,
            summerEmitter: apartment.summerEmitter
              ? apartment.summerEmitter.name
              : apartment.summerEmitter,
          }
        }),
      }
    })
    dispatch('setIsNotValid')
    await dispatch('saveRequest', data)
  },
  async resetCoolingLoad({ dispatch }) {
    const floors = this.$storage.getUniversal('calculation-request-floors')
    const appFloors = floors.map((floor) => {
      return {
        number: floor.number,
        commercialCode: floor.commercialCode,
        apartments: floor.apartments.map((apartment) => {
          return {
            code: apartment.code,
            heatingLoad: apartment.heatingLoad,
            flowRate: apartment.flowRate,
            coolingLoad: null,
            winterEmitter: apartment.winterEmitter,
            summerEmitter: null,
          }
        }),
      }
    })
    this.$storage.setUniversal('calculation-request-floors', appFloors)

    const data = { update: true, floors }
    data.floors = data.floors.map((floor) => {
      return {
        number: floor.number,
        commercialCode: floor.commercialCode,
        apartments: floor.apartments.map((apartment) => {
          return {
            code: apartment.code,
            heatingLoad: apartment.heatingLoad,
            flowRate: apartment.flowRate,
            coolingLoad: null,
            winterEmitter: apartment.winterEmitter
              ? apartment.winterEmitter.name
              : apartment.winterEmitter,
            summerEmitter: null,
          }
        }),
      }
    })
    await dispatch('saveRequest', data)
  },
  async setFloors({ dispatch, commit }, value) {
    let floors = []
    const storageFloors = await dispatch('getFloors')
    if (storageFloors && storageFloors.length > 0) {
      const diff = value.length - storageFloors.length
      for (let floorI = value.length - 1; floorI >= 0; floorI--) {
        const floorIdx = floorI - diff
        const floor = storageFloors[floorIdx]
        const valFloor = value[floorI]
        let apartments = []
        for (
          let apartmentI = 0;
          apartmentI < valFloor._apartments;
          apartmentI++
        ) {
          if (floor && floor.apartments[apartmentI]) {
            let code = floor.apartments[apartmentI].code
            if (valFloor._commercial) {
              if (!code.includes('C')) {
                code = 'C' + (value.length - floorI)
              }
            } else if (code.includes('C')) {
              code = code.split('C')[1]
            }
            apartments = apartments.concat({
              code,
              heatingLoad:
                (!floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial) ||
                (floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial)
                  ? null
                  : floor.apartments[apartmentI].heatingLoad,
              coolingLoad:
                (!floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial) ||
                (floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial)
                  ? null
                  : floor.apartments[apartmentI].coolingLoad,
              flowRate: floor.apartments[apartmentI].flowRate,
              winterEmitter:
                (!floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial) ||
                (floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial)
                  ? null
                  : floor.apartments[apartmentI].winterEmitter,
              summerEmitter:
                (!floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial) ||
                (floor.apartments[apartmentI].code.includes('C') &&
                  valFloor._commercial)
                  ? null
                  : floor.apartments[apartmentI].summerEmitter,
            })
          } else {
            apartments = apartments.concat({
              code: valFloor._commercial
                ? `C${apartmentI + 1}`
                : `${apartmentI + 1}`,
              heatingLoad: null,
              coolingLoad: null,
              flowRate: null,
              winterEmitter: null,
              summerEmitter: null,
            })
          }
        }
        const newFloor = {
          number: valFloor.id,
          commercialCode: valFloor._commercial,
          apartments,
        }
        floors.unshift(newFloor)
      }
    } else {
      for (let index = 0; index < value.length; index++) {
        const floor = value[index]
        const apartments = []
        for (let index = 1; index <= floor._apartments; index++) {
          apartments.push({
            code: floor._commercial ? `C${index}` : `${index}`,
            heatingLoad: null,
            coolingLoad: null,
            flowRate: null,
            winterEmitter: null,
            summerEmitter: null,
          })
        }
        const newFloor = {
          number: floor.id,
          commercialCode: floor._commercial,
          apartments,
        }
        floors = floors.concat(newFloor)
      }
    }
    this.$storage.setUniversal('calculation-request-floors', floors)
    dispatch('setIsNotValid')
  },
  async getBuildingLayoutFloors({ dispatch }) {
    const floors = await dispatch('getFloors')
    const copyFloors = []
    for (let index = 0; index < floors.length; index++) {
      const f = floors[index]
      copyFloors.push({
        id: f.number,
        _apartments: f.apartments.length,
        _commercial: f.commercialCode,
      })
    }
    return copyFloors
  },
  getFloors() {
    return this.$storage.getUniversal('calculation-request-floors')
  },
  setPlateHeatExchanger(_, value) {
    this.$storage.setUniversal(
      'calculation-request-plate-heat-exchanger',
      value
    )
  },
  getPlateHeatExchanger() {
    return this.$storage.getUniversal(
      'calculation-request-plate-heat-exchanger'
    )
  },
  setGlycolPercentage(_, value) {
    this.$storage.setUniversal('calculation-request-glycol-percentage', value)
  },
  getGlycolPercentage() {
    return this.$storage.getUniversal('calculation-request-glycol-percentage')
  },
  setMaxPressureLoss(_, value) {
    this.$storage.setUniversal('calculation-request-max-pressure-loss', value)
  },
  getMaxPressureLoss() {
    return this.$storage.getUniversal('calculation-request-max-pressure-loss')
  },
  setAverageFloorToFloorHeight(_, value) {
    this.$storage.setUniversal('calculation-request-average-height', value)
  },
  getAverageFloorToFloorHeight() {
    return this.$storage.getUniversal('calculation-request-average-height')
  },
  setDhwDiversityFactor(_, value) {
    this.$storage.setUniversal('calculation-request-dhw-diversity', value)
  },
  getDhwDiversityFactor() {
    return this.$storage.getUniversal('calculation-request-dhw-diversity')
  },
  setOccupancyDiversityFactor(_, value) {
    this.$storage.setUniversal('calculation-request-occupancy-diversity', value)
  },
  getOccupancyDiversityFactor() {
    return this.$storage.getUniversal('calculation-request-occupancy-diversity')
  },
  setSelectedBuildingDemand(_, value) {
    this.$storage.setUniversal('calculation-request-building-demand', value)
  },
  getSelectedBuildingDemand(_, buildingDemand) {
    let selectedBuildingDemand = this.$storage.getUniversal(
      'calculation-request-building-demand'
    )
    if (!selectedBuildingDemand) {
      selectedBuildingDemand = buildingDemand[0]
    }
    return selectedBuildingDemand
  },
  setSelectedCountry(_, value) {
    this.$storage.setUniversal('calculation-request-country', value)
  },
  getSelectedCountry(_, countries) {
    let selectedCountry = this.$storage.getUniversal(
      'calculation-request-country'
    )
    if (!selectedCountry) {
      selectedCountry = countries[0]
    }
    return selectedCountry
  },
  async getCountries() {
    const countries = await this.$directusApi.getCountries()
    return countries
  },
  async setStep1({ dispatch }, value) {
    this.$storage.setUniversal('calculation-request-country', value.country)
    this.$storage.setUniversal('calculation-request-region', value.region)

    const buildingDemand = this.$storage.getUniversal(
      'calculation-request-building-demand'
    )
    this.$storage.setUniversal(
      'calculation-request-building-demand',
      value.buildingDemand
    )
    if (
      buildingDemand &&
      value.buildingDemand._buildingDemand === 'H/O' &&
      buildingDemand._buildingDemand === 'H/C'
    ) {
      await dispatch('resetCoolingLoad')
    }
    const data = {
      sizingTool: {
        country: value.country._excelName,
        region: value.region.name,
        outsideDesignTemperature: value.outsideDesignTemperature,
        buildingDemand: value.buildingDemand._buildingDemand,
        centralHeatingSource: value.centralHeatingSource._centralHeatingSource,
      },
    }
    await dispatch('saveRequest', data)
  },
  async setStep2({ dispatch }, value) {
    await dispatch('setPlateHeatExchanger', value.useOfCentralPhex)
    await dispatch('setGlycolPercentage', value.glycolMixture)
    await dispatch('setMaxPressureLoss', value.maxPressureLossAllowed)
    const data = {
      sizingTool: {
        useOfCentralPhex: value.useOfCentralPhex._useOfCentralPhex,
        glycolMixture: value.glycolMixture._glycolMixture,
        maxPressureLossAllowed: value.maxPressureLossAllowed,
      },
    }
    await dispatch('saveRequest', data)
  },
  async setStep3({ dispatch }) {
    const floors = await dispatch('getFloors')
    const averageFloorToFloorHeight = await dispatch(
      'getAverageFloorToFloorHeight'
    )
    const data = {
      update: true,
      sizingTool: {
        averageFloorToFloorHeight,
      },
      floors,
    }
    await dispatch('saveRequest', data)
  },
  async setStep4({ dispatch }) {
    const dhwDiversityFactor = await dispatch('getDhwDiversityFactor')
    const occupancyDiversityFactor = await dispatch(
      'getOccupancyDiversityFactor'
    )
    const data = {
      sizingTool: {
        dhwDiversityFactor: dhwDiversityFactor || '',
        occupancyDiversityFactor: `${occupancyDiversityFactor.toString()}%`,
      },
    }
    await dispatch('saveRequest', data)
  },
  async getIllustrationsData() {
    const illustrations = await this.$directusApi.getIllustrationsData()
    return illustrations
  },
  getCalculatedResult() {
    return this.$storage.getUniversal('calculation-response')
  },
  async setCalculatedResult({ dispatch }, value) {
    try {
      await dispatch('setIsLoading', true)
      const payload = this.$storage.getUniversal('calculation-request')
      const response = await this.$backendApi.setCalculatedResult(payload)
      if (response === null || response === 'null') {
        await dispatch('setHasErrors', true)
        return false
      }
      if (response) {
        this.$storage.setUniversal('calculation-response', response)
      }
      await dispatch('setIsLoading', false)
      await dispatch('setHasErrors', false)
    } catch (e) {
      await dispatch('setHasErrors', true)
      return false
    }
  },
  getRequest() {
    return this.$storage.getUniversal('calculation-request')
  },
  async saveRequest({ dispatch }, data) {
    let payload = {}
    const oldPayload = await dispatch('getRequest')
    if (oldPayload) {
      if (data.update) {
        delete data.update
        delete oldPayload.floors
      }
      payload = merge(oldPayload, data)
    } else {
      payload = data
    }
    this.$storage.setUniversal('calculation-request', payload)
  },
}
