
export default {
  computed: {
    htmlClasses() {
      const arr = []
      if (this.hasDisableScroll || this.showingPopup) {
        arr.push('disable-scroll')
      } else if (this.isMobile) {
        arr.push('is-mobile')
      }
      return arr.join()
    },
    hasDisableScroll() {
      return this.$store.state.components.disableScroll
    },
    showingPopup() {
      return this.$store.state.components.popUpVisible
    },
    hasFooter() {
      return this.$store.state.components.footerVisible
    },
    isMobile() {
      return this.$store.state.components.isMobile
    },
    meta() {
      if (process.env.envName === 'staging') {
        return [
          {
            name: 'robots',
            content: 'noindex',
          },
          {
            name: 'googlebot',
            content: 'noindex',
          },
        ]
      }
      return ''
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: this.htmlClasses,
      },
      meta: this.meta,
    }
  },
}
