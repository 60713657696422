import { render, staticRenderFns } from "./ContentBox.vue?vue&type=template&id=36e4ce74&"
import script from "./ContentBox.vue?vue&type=script&lang=js&"
export * from "./ContentBox.vue?vue&type=script&lang=js&"
import style0 from "./ContentBox.vue?vue&type=style&index=0&id=36e4ce74&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkButton: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/ck/Button/index.vue').default,Flex: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/styledcomponents/Flex.js').default,SharedWrapper: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/shared/Wrapper.vue').default,Color: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/styledcomponents/Color.js').default})
