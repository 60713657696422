
export default {
  props: {
    imagePosition: {
      type: String,
      default: 'left',
    },
    imageSource: String,
    imageType: {
      type: String,
      default: 'png',
    },
    imageWidth: String,
    imageHeight: String,
    symmetrical: Boolean,
    background: String,
    center: Boolean,
  },
  computed: {
    backgroundColor() {
      let color = ''
      switch (this.background) {
        case 'blue':
          color = 'blue-100'
          break
        case 'gray':
          color = 'neutral-050'
          break
        default:
          color = 'white'
          break
      }
      return color
    },
    newImageWidth() {
      if (this.symmetrical) {
        return this.imageWidth || '100%'
      }
      return this.imageWidth || '100%'
    },
    newImageHeight() {
      if (this.symmetrical) {
        return this.imageHeight || 'auto'
      }
      return this.imageHeight || '658'
    },
  },
}
