import styled from 'vue-styled-components'
import colors from '~/components/ck/theme'

const componentProps = {
  hover: String,
}

const StyledSvgHover = styled('span', componentProps)`
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover .ico {
    fill: ${(props) => colors[props.hover]};
    use,
    use + *,
    * {
      fill: ${(props) => colors[props.hover]};
    }
  }
`

export default StyledSvgHover
