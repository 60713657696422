import { render, staticRenderFns } from "./error.vue?vue&type=template&id=6552402d&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=6552402d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6552402d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SharedContentTitle: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/shared/ContentTitle.vue').default})
