export const state = () => ({
  cookies: true,
  stateKeys: ['cookies'],
})

export const mutations = {
  setCookie(state, value) {
    state.cookies = value
  },
}
export const actions = {
  clearStorage(state) {
    for (let i = 0; i < state.stateKeys.length; i++) {
      this.$storage.removeUniversal(state.stateKeys[i])
    }
  },
  getCookies(state) {
    return this.$storage.getUniversal('cookies')
  },
  async setCookies({ state, dispatch, commit }, cookies) {
    const storageCookies = await dispatch('getCookies')
    if (storageCookies === null && cookies === undefined) {
      this.$storage.setUniversal('cookies', true)
    }
    if (storageCookies === false) {
      commit('setCookie', false)
    }
    if (cookies === false) {
      this.$storage.setUniversal('cookies', false)
      commit('setCookie', false)
    }
  },
  clearCookies({ dispatch }) {
    dispatch('clearStorage')
  },
}
