export default function (ctx) {
  const directusAxios = ctx.$axios.create()

  directusAxios.setHeader('Content-Type', 'application/json')

  let baseUrl = null

  switch (ctx.env.envName) {
    case 'master':
      baseUrl = ctx.env.apiProdDirectus
      break
    case 'staging':
      baseUrl = ctx.env.apiStagingDirectus
      break
    case 'develop':
      baseUrl = ctx.env.apiDevDirectus
      break
    case 'feature':
      baseUrl = ctx.env.apiFeatureDirectus
      break

    default:
      baseUrl = ctx.env.apiStagingDirectus
  }

  ctx.store.commit('setBaseUrl', baseUrl)
  directusAxios.setBaseURL(baseUrl)

  directusAxios.onRequest((config) => {
    if (ctx.store.state.auth.token) {
      config.headers.common.authorization =
        'Bearer ' + ctx.store.state.auth.token
    }
    console.log('🚀 Making request to directus api ' + config.url)
  })

  directusAxios.onResponse((response) => {
    // console.log('🗣 Response: ', response.data)
  })

  directusAxios.onError((axiosError) => {
    const code = parseInt(axiosError.response && axiosError.response.status)
    console.log('🥊 Error with request: ', code)
  })

  ctx.$directusAxios = directusAxios
}
