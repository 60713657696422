import { render, staticRenderFns } from "./Popups.vue?vue&type=template&id=093d797c&"
import script from "./Popups.vue?vue&type=script&lang=js&"
export * from "./Popups.vue?vue&type=script&lang=js&"
import style0 from "./Popups.vue?vue&type=style&index=0&id=093d797c&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupsEnergyComparisonPopup: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/pages/popups/EnergyComparisonPopup.vue').default,PopupsTermsOfUsePopup: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/pages/popups/TermsOfUsePopup.vue').default})
