
export default {
  props: ['error'],
  head() {
    return {
      bodyAttrs: {
        class: this.error.statusCode ? 'error-page' : '',
      },
    }
  },
}
