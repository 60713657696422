export default function (ctx, inject) {
  const preUrl = '_/items/'
  const directusApi = {
    async getCountries() {
      const response = await ctx.$directusAxios.get(
        `${preUrl}countries?fields=*.*`
      )
      const data = response?.data?.data
        .filter((c) => c.engineering_guide)
        .map((c) => {
          return {
            id: c.id,
            i18n: c.translation_keyword.keyword,
            imageUrl: c.flag?.data?.full_url,
            _excelName: c.excel_name,
            _shortName: c.short_name,
          }
        })
      return data
    },
    async getRegions(country) {
      try {
        const response = await ctx.$directusAxios.get(
          `${preUrl}regions?fields=*.*.*&filter[country.short_name]=${country}`
        )
        const data = response?.data?.data[0]?.regions.regions.map((c, i) => {
          return {
            id: i + 1,
            name: c.name,
            _default: response.data.data[0].default_region.name === c.name,
            _outsideDesignTemp: c.outsideDesignTemp,
          }
        })
        return data
      } catch (err) {
        console.log('err: ', err)
        return []
      }
    },
    async getIllustrationsData() {
      const response = await ctx.$directusAxios.get(
        `${preUrl}schemes?fields=*.*`
      )
      return response.data.data[0].structure.illustrations
    },
    async getProductGroups() {
      const response = await ctx.$directusAxios.get(
        `${preUrl}product_groups?fields=*.*`
      )

      const data = response?.data?.data?.map((pg) => {
        return {
          id: pg.id,
          slug: pg.slug,
          name: pg.name,
          imageUrl: pg.image?.data?.full_url,
          imageCardUrl: pg.image_card?.data?.full_url,
          description: pg.description?.keyword,
          descriptionCard: pg.description_card?.keyword,
          installationType: pg.installation_type,
          sound: pg.sound,
          functions: pg.functions,
          size: pg.size?.keyword,
          maxLwt: pg.max_lwt,
          maxDistance: pg.max_distance,
          maxDistanceTank: pg.max_distance_tank,
          maxHpCascade: pg.max_n_hp_cascade,
          buildingSize: pg.building_size,
          heatingDegree: pg.heating_degree,
          dhw: pg.dhw,
          show: pg.show,
          hsnLink: pg.hsn_link,
          heatingTooltipText: pg.heating_tooltip_text?.keyword,
          coolingTooltipText: pg.cooling_tooltip_text?.keyword,
          dhwTooltipText: pg.dhw_tooltip_text?.keyword,
          activeCultures: pg.active_cultures,
        }
      })
      return data
    },
    async getCultures() {
      try {
        const response = await ctx.$directusAxios.get(
          `${preUrl}cultures?fields=*.*`
        )
        if (response?.data?.data?.length) {
          const data = response.data.data.filter((lang) => lang.active)
          return data
        }
      } catch (err) {
        console.log('err: ', err)
        return []
      }
    },
  }
  ctx.$directusApi = directusApi
  inject('directusApi', directusApi)
}
