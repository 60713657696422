import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=0a545b76&scoped=true&"
import script from "./Wrapper.vue?vue&type=script&lang=js&"
export * from "./Wrapper.vue?vue&type=script&lang=js&"
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=0a545b76&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a545b76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Flex: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/styledcomponents/Flex.js').default})
