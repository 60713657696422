import Vue from 'vue'

export function dateTime(epoch, region) {
  if (!epoch) {
    return ''
  }
  const dateValue = new Date(epoch)
  let hourPart = 'u'
  if (region !== 'nl-BE') {
    hourPart = 'h'
  }
  const timePart = `${dateValue.getHours()}${hourPart}${`${dateValue.getMinutes()}`.padStart(
    2,
    '0'
  )}`
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const dateFormat = dateValue.toLocaleDateString(region || 'nl-BE', options)
  return `${dateFormat} - ${timePart}`
}

export function dateOnly(epoch, region) {
  if (!epoch) {
    return ''
  }
  const dateValue = new Date(epoch)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const dateFormat = dateValue.toLocaleDateString(region || 'nl-BE', options)
  return dateFormat
}

export function replaceEmpty(value, defaultValue) {
  if (!defaultValue) return value
  if (!value || value === '') {
    return defaultValue
  }
  return value
}

const filters = { dateTime, replaceEmpty, dateOnly }

export default Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
