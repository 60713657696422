export default (ctx, inject) => {
  ctx.$gtm.init(ctx.env.gtmId)

  inject('getLabel', (category, extra) => {
    if (!category) {
      return
    }
    switch (category) {
      case 'menu':
        return { label: `ButtonName_${extra}`, action: 'click' }
      case 'discover':
        if (extra === 'highRise') {
          return { label: 'high rise solutions', action: 'click' }
        }
        return { label: 'low rise solutions', action: 'click' }
      case 'get-started':
        return { label: null, action: 'click' }
      case 'button':
        return { label: `ButtonName_${extra}`, action: null }
      case 'calculatortool':
        if (extra === 'success') {
          return { label: 'Calculation succeeded!', action: null }
        }
        return {
          label: `Calculation failed. An error has occurred.`,
          action: null,
        }
      case 'download-results':
        return { label: null, action: 'download' }
    }
  })

  inject('gtmButtonClicked', (category, extra) => {
    const myEvent = {
      event: 'Click on button',
      category,
    }
    const label = ctx.app.$getLabel(category, extra).label
    if (label) {
      myEvent.label = label
    }
    const action = ctx.app.$getLabel(category, extra).action
    if (action) {
      myEvent.action = action
    }
    console.log('gtmButtonClicked', myEvent)
    ctx.$gtm.push(myEvent)
  })
}
