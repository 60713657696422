
export default {
  props: {
    next: [Boolean, String],
    previous: [Boolean, String],
    nextDisabled: Boolean,
    sidepanel: Boolean,
    noHr: Boolean,
  },
}
