
export default {
  data() {
    return {
      relative: false,
    }
  },
  computed: {
    cookies() {
      return this.$store.state.cookies.cookies
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      if (this.$refs.notification) {
        const footer =
          this.$parent.$children[this.$parent.$children.length - 2].$refs.footer
        const notificationOffsetHeight = this.$refs.notification.offsetHeight
        const footerOffsetHeight = footer ? footer.offsetHeight : 0
        // const margin = 60
        const height = document.getElementById('__nuxt').offsetHeight

        if (this.relative) {
          if (
            window.pageYOffset <
            height -
              footerOffsetHeight -
              notificationOffsetHeight -
              window.innerHeight
          ) {
            this.relative = false
          }
        }

        if (!this.relative) {
          if (window.pageYOffset + window.innerHeight >= height) {
            this.relative = true
          }
        }
      }
    },
    setCookiePolicy() {
      this.$store.dispatch('cookies/setCookies', false)
    },
  },
}
