import { render, staticRenderFns } from "./CookiePolicyNotification.vue?vue&type=template&id=43489484&scoped=true&"
import script from "./CookiePolicyNotification.vue?vue&type=script&lang=js&"
export * from "./CookiePolicyNotification.vue?vue&type=script&lang=js&"
import style0 from "./CookiePolicyNotification.vue?vue&type=style&index=0&id=43489484&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43489484",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkIcon: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/ck/Icon/index.js').default})
