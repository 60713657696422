export const state = () => ({
  popUpVisible: false,
  popUpName: null,
  popUpData: null,
  footerVisible: false,
  disableScroll: false,
  isMobile: false,
})

export const mutations = {
  setIsMobile(state, value) {
    state.isMobile = value
  },
  setShowPopup(state, data) {
    if (typeof data === 'object') {
      state.popUpName = data.name
      state.popUpData = data.data
    } else {
      state.popUpName = data
    }
    state.popUpVisible = true
  },
  setHidePopup(state) {
    state.popUpName = null
    state.popUpData = null
    state.popUpVisible = false
  },
  setDisableScroll(state, value) {
    state.disableScroll = value
  },
}
