export default {
  black: '#000000',
  white: '#ffffff',

  // Library colors
  'blue-100': 'rgba(244, 251, 254, 1)',
  'blue-300': 'rgba(202, 239, 255, 1)',
  'blue-500': 'rgba(36, 172, 255, 1)',
  'blue-700': 'rgba(0, 103, 165, 1)',

  'green-100': 'rgba(214, 248, 231, 1)',
  'green-300': 'rgba(129, 218, 173, 1)',
  'green-500': 'rgba(44, 186, 115, 1)',

  'neutral-050': 'rgba(249, 249, 249, 1)',
  'neutral-100': 'rgba(235, 238, 248, 1)',
  'neutral-200': 'rgba(214, 219, 234, 1)',
  'neutral-300': 'rgba(176, 184, 208, 1)',
  'neutral-400': 'rgba(139, 150, 184, 1)',
  'neutral-500': 'rgba(111, 121, 151, 1)',
  'neutral-600': 'rgba(75, 83, 110, 1)',
  'neutral-700': 'rgba(47, 53, 72, 1)',
  'neutral-800': 'rgba(27, 31, 43, 1)',

  'orange-100': 'rgba(255, 240, 216, 1)',
  'orange-300': 'rgba(255, 215, 153, 1)',
  'orange-500': 'rgba(255, 185, 75, 1)',

  'pink-100': 'rgba(245, 211, 245, 1)',
  'pink-300': 'rgba(233, 167, 233, 1)',
  'pink-500': 'rgba(210, 107, 210, 1)',

  'red-100': 'rgba(255, 229, 229, 1)',
  'red-300': 'rgba(255, 168, 168, 1)',
  'red-500': 'rgba(255, 100, 100, 1)',

  'teal-100': 'rgba(210, 244, 244, 1)',
  'teal-300': 'rgba(127, 215, 214, 1)',
  'teal-500': 'rgba(44, 186, 185, 1)',

  'violet-100': 'rgba(229, 222, 250, 1)',
  'violet-300': 'rgba(181, 164, 230, 1)',
  'violet-500': 'rgba(134, 107, 210, 1)',

  // Project colors
  'blue-350': 'rgba(183, 228, 255, 1)',
  'blue-400': 'rgba(79, 187, 253, 1)',
  'blue-600': 'rgba(0, 140, 225, 1)',
  'blue-800': '#092a40',

  /* STATES */
  default: '#3D9AE3',
  warning: '#FFB61A',
  error: '#FF583B',

  knownColor(value) {
    if (this[value]) {
      return this[value]
    }
    return value
  },
  convertHex(hex, opacity) {
    const hexValue = hex.replace('#', '')
    const r = parseInt(hexValue.substring(0, 2), 16)
    const g = parseInt(hexValue.substring(2, 4), 16)
    const b = parseInt(hexValue.substring(4, 6), 16)

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
  },
  knownColorWithOpacity(value, opacity) {
    if (!this[value].includes('#')) {
      return this.changeOpacityRgba(value, opacity)
    }
    if (this[value]) {
      const hexValue = this.colorcolor(this[value], 'hex')
      return this.convertHex(hexValue, opacity)
    }
    const hexRawValue = this.colorcolor(value, 'hex')
    return this.convertHex(hexRawValue, opacity)
  },
  changeOpacityRgba(value, opacity) {
    const regExp = /\(([^)]+)\)/
    const matches = regExp.exec(this[value])
    const rgbArray = matches[1]?.split(', ')
    return (
      'rgba(' +
      rgbArray[0] +
      ',' +
      rgbArray[1] +
      ',' +
      rgbArray[2] +
      ',' +
      opacity / 100 +
      ')'
    )
  },
  colorcolor(color, newColor, calculateOpacity) {
    if (!newColor) {
      newColor = 'rgba'
    }
    if (!calculateOpacity) {
      calculateOpacity = false
    }
    color = color.toLowerCase()
    newColor = newColor.toLowerCase()
    let returnedColor = color
    let r, g, b, a
    const roundTo = 4
    const colorDefinitions = {
      hex: {
        re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
        example: ['00ff00', '336699'],
        toRGBA(bits) {
          return [
            parseInt(bits[1], 16),
            parseInt(bits[2], 16),
            parseInt(bits[3], 16),
            1,
          ]
        },
      },
    }

    // Search the color definitions for a match
    for (const colorDefinition in colorDefinitions) {
      const re = colorDefinitions[colorDefinition].re
      const processor = colorDefinitions[colorDefinition].toRGBA
      const bits = re.exec(color)
      if (bits) {
        const channels = processor(bits)
        r = channels[0]
        g = channels[1]
        b = channels[2]
        a = +(Math.round(channels[3] + ('e+' + roundTo)) + ('e-' + roundTo))
      }
    }
    r = Math.round(r < 0 || isNaN(r) ? 0 : r > 255 ? 255 : r)
    g = Math.round(g < 0 || isNaN(g) ? 0 : g > 255 ? 255 : g)
    b = Math.round(b < 0 || isNaN(b) ? 0 : b > 255 ? 255 : b)
    a = a < 0 || isNaN(a) ? 0 : a > 1 ? 1 : a

    switch (newColor) {
      case 'hex':
        returnedColor =
          '#' +
          ('0' + r.toString(16)).slice(-2) +
          ('0' + g.toString(16)).slice(-2) +
          ('0' + b.toString(16)).slice(-2)
        break
      default:
        if (calculateOpacity) {
          ;[r, g, b, a] = this.calculateOpacityFromWhite(r, g, b, a)
        }
        returnedColor = `rgba(${r},${g},${b},${a})`
        break
    }

    return returnedColor
  },
  calculateOpacityFromWhite(r, g, b, a) {
    let min = 0
    a = (255 - (min = Math.min(r, g, b))) / 255
    r = (0 || (r - min) / a).toFixed(0)
    g = (0 || (g - min) / a).toFixed(0)
    b = (0 || (b - min) / a).toFixed(0)
    a = parseFloat(a.toFixed(4))

    return [r, g, b, a]
  },
  toPercent(amount, limit) {
    return amount / limit
  },
}
