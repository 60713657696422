import { render, staticRenderFns } from "./TermsOfUsePopup.vue?vue&type=template&id=0435478c&"
import script from "./TermsOfUsePopup.vue?vue&type=script&lang=js&"
export * from "./TermsOfUsePopup.vue?vue&type=script&lang=js&"
import style0 from "./TermsOfUsePopup.vue?vue&type=style&index=0&id=0435478c&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkCheckbox: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/ck/Checkbox.vue').default,CkPopup: require('/Users/arjen/Documents/Aaltra/Projecten/daikin_collectivehousing/vue/components/ck/Popup.vue').default})
