export default (ctx, inject) => {
  inject('systemPopover', (value) => {
    switch (value) {
      case 'echo':
        return {
          title: ctx.app.i18n.t('echoPopoverTitle'),
          description: ctx.app.i18n.t('echoPopoverDescription'),
          image: '/img/cascade/echo-tank',
        }
      case 'controller':
        return {
          title: ctx.app.i18n.t('controllerPopoverTitle'),
          description: ctx.app.i18n.t('controllerPopoverDescription'),
          image: '/img/cascade/controller',
        }

      default:
        return {
          title: ctx.app.i18n.t('echoPopoverTitle'),
          description: ctx.app.i18n.t('echoPopoverDescription'),
          image: '/img/cascade/echo-tank',
        }
    }
  })

  inject('getStoredProductGroups', () => {
    return ctx.app.store.state.cascade.productGroups
  })

  inject('getSizingToolUrl', () => {
    switch (ctx.env.envName) {
      case 'master':
        return ctx.env.sizingToolUrlProd
      case 'staging':
        return ctx.env.sizingToolUrlStaging
      case 'develop':
        return ctx.env.sizingToolUrlStaging
      case 'feature':
        return ctx.env.sizingToolUrlFeature
      default:
        return ctx.env.sizingToolUrlStaging
    }
  })
}
