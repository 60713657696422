export default function (ctx, inject) {
  const backendApi = {
    async setCalculatedResult(payload) {
      const response = await ctx.$backendAxios.post('calculation', payload)
      return response.data
    },
  }
  ctx.$backendApi = backendApi
  inject('backendApi', backendApi)
}
