import Storage from '@headbright/hb-universal-storage'

export default function (ctx, inject) {
  const options = {
    vuex: {
      namespace: 'storage',
    },
    cookie: false,
    localStorage: {
      prefix: '',
    },
    ignoreExceptions: false,
  }
  const storage = new Storage(ctx, options)
  ctx.$storage = storage
  inject('storage', storage)
}
