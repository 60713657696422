export default (ctx, inject) => {
  inject('formatLiter', (value) => {
    if (!value) return '-'

    return `${value} l`
  })

  inject('formatDecibel', (value) => {
    if (!value) return '-'

    return ctx.app.i18n.t('dbaValue', [value])
  })

  inject('formatMeter', (value) => {
    if (!value) return '-'

    return `${value} m`
  })

  inject('formatDegreesCelsius', (value) => {
    if (!value) return '-'

    return `${value} °C`
  })
}
