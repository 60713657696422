import styled from 'vue-styled-components'
import colors from '~/components/ck/theme'

const componentProps = {
  fill: String,
  size: {
    type: String,
    default: '24px',
  },
  width: {
    type: String,
    default: '24px',
  },
  height: {
    type: String,
    default: '24px',
  },
}

const StyledSvg = styled('span', componentProps)`
  &.ico {
    fill: ${(props) => (props.fill ? colors[props.fill] : colors['grey-dark'])};
    use,
    use + *,
    * {
      fill: ${(props) =>
        props.fill ? colors[props.fill] : colors['grey-dark']};
    }
    width: ${(props) => (props.size ? props.size : props.width)};
    height: ${(props) => (props.size ? props.size : props.height)};
  }
`

export default StyledSvg
