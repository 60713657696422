
export default {
  props: {
    name: String,
    noAction: Boolean,
    actionRight: String,
    actionLeft: String,
    delete: Boolean,
    preventHide: Boolean,
    skipvalidation: Boolean,
    disabled: Boolean,
    closeButton: Boolean,
    widthSize: {
      type: String,
      default: '465px',
    },
  },
  computed: {
    popupItem() {
      return this.$refs.popupRoot
    },
    isDisabled() {
      if (this.disabled) {
        return true
      }
      return false
    },
    isConfirm() {
      return !!this.actionRight || this.actionRight === ''
    },
    isCancel() {
      return !!this.actionLeft || this.actionLeft === ''
    },
    isDelete() {
      return !!this.delete || this.delete === ''
    },
  },
  methods: {
    doHide() {
      if (!this.preventHide) {
        this.$store.commit('components/setHidePopup')
      }
      this.$emit('close')
    },
    doConfirm() {
      if (this.isDisabled) {
        return
      }
      if (this.skipvalidation) {
        this.$emit('confirm')
        this.doHide()
      }
    },
  },
}
