const locales = [
  {
    id: 1,
    lang: 'en',
    country: 'GB',
    name: 'United Kingdom',
    code: 'en-GB',
    file: 'en-GB.json',
    flag: 'flag-en-GB',
  },
  {
    id: 2,
    lang: 'nl',
    country: 'BE',
    name: 'België',
    code: 'nl-BE',
    file: 'nl-BE.json',
    flag: 'flag-be',
  },
  {
    id: 3,
    lang: 'fr',
    country: 'BE',
    name: 'België',
    code: 'fr-BE',
    file: 'fr-FR.json',
    flag: 'flag-be',
  },
  {
    id: 4,
    lang: 'de',
    country: 'DE',
    name: 'Deutsch',
    code: 'de-DE',
    file: 'de-DE.json',
    flag: 'flag-de-DE',
  },
  {
    id: 5,
    lang: 'de',
    country: 'AT',
    name: 'Österreich',
    code: 'de-AT',
    file: 'de-AT.json',
    flag: 'flag-de-AT',
  },
  {
    id: 6,
    lang: 'fr',
    country: 'FR',
    name: 'France',
    code: 'fr-FR',
    file: 'fr-FR.json',
    flag: 'flag-fr-FR',
  },
  {
    id: 7,
    lang: 'es',
    country: 'ES',
    name: 'Español',
    code: 'es-ES',
    file: 'es-ES.json',
    flag: 'flag-es-ES',
  },
  {
    id: 8,
    lang: 'it',
    country: 'IT',
    name: 'Italia',
    code: 'it-IT',
    file: 'it-IT.json',
    flag: 'flag-it-IT',
  },
]

export default locales
