export default function (ctx) {
  const backendAxios = ctx.$axios.create()

  backendAxios.setHeader('Content-Type', 'application/json')

  let baseUrl = null
  if (ctx.env.envName === 'master') {
    baseUrl = ctx.env.apiProdBackend
  } else {
    baseUrl = ctx.env.apiStagingBackend
  }
  backendAxios.setBaseURL(baseUrl)

  backendAxios.onRequest((config) => {
    if (ctx.store.state.auth.token) {
      config.headers.common.authorization =
        'Bearer ' + ctx.store.state.auth.token
    }
    console.log('🚀 Making request to backend api ' + config.url)
  })

  backendAxios.onResponse((response) => {
    // console.log('🗣 Response: ', response.data)
  })

  backendAxios.onError((axiosError) => {
    const code = parseInt(axiosError.response && axiosError.response.status)
    console.log('🥊 Error with request: ', code)
  })

  ctx.$backendAxios = backendAxios
}
