import styled from 'vue-styled-components'
import colors from '~/components/ck/theme'

// use <button-color :background="'white'" :background-opacity="'30'" :border="'black'" :text="'black'"></color>

const componentProps = {
  background: String,
  backgroundOpacity: Number,
  border: String,
  borderRadius: String,
  text: String,
}

const ButtonColorDiv = styled('div', componentProps)`
  background-color: ${(props) =>
    props.backgroundOpacity == null
      ? colors.knownColor(props.background)
      : colors.knownColorWithOpacity(
          props.background,
          props.backgroundOpacity
        )} !important;
  border-color: ${(props) =>
    props.backgroundOpacity == null
      ? colors.knownColor(props.background)
      : colors.knownColorWithOpacity(
          props.background,
          props.backgroundOpacity
        )} !important;

  &:hover,
  &:focus {
    &:not(.secondary):not(.toggle):not(.no-hover):not(.disabled) {
      box-shadow: 0 5px 10px 0
        ${(props) => colors.knownColorWithOpacity('blue-800', 20)};
    }
  }
`

export default {
  props: {
    background: String,
    backgroundOpacity: Number,
    border: String,
    borderRadius: String,
    text: String,
  },
  render(h) {
    return (
      <ButtonColorDiv
        background={this.background}
        background-opacity={this.backgroundOpacity}
        border={this.border}
        border-radius={this.borderRadius}
        text={this.text}
        class="btn"
      >
        {this.$slots.default}
      </ButtonColorDiv>
    )
  },
}
