export const state = () => ({
  productGroups: [],
  cultures: [],
  activeLocale: null,
})

export const getters = {
  activeProductGroups: (state) => {
    return state.productGroups.filter((item) => item.show)
  },
}

export const mutations = {
  setProductGroups(state, value) {
    state.productGroups = value
  },
  setCultures(state, value) {
    state.cultures = value
  },
  setActiveLocale(state, value) {
    state.activeLocale = value
  },
}

export const actions = {
  async getProductGroups({ state, commit }) {
    const response = await this.$directusApi.getProductGroups()
    const activeCulture = state.activeCulture ?? this.$i18n.localeProperties
    let activeProductGroups = []
    if (response.length) {
      activeProductGroups = response.filter((productGroup) => {
        if (!productGroup.activeCultures) return false
        for (const culture of productGroup.activeCultures) {
          if (culture.cultures_id === activeCulture?.id) {
            return true
          }
        }
      })
    }
    commit('setProductGroups', activeProductGroups)
    return activeProductGroups
  },
  async getCultures({ state, commit }) {
    const response = await this.$directusApi.getCultures()
    commit('setCultures', response || [])
  },
}
